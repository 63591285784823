import React from "react";
import classNames from 'classnames'
import '../assets/scss/components/snackbar.scss'; 
import { useStoreActions, useStoreState } from "easy-peasy";

export function Snackbar(props) {
    const { message, type, top } = props;
    const snackbarClass = classNames({
        'snackbar': true,
        'snackbar--danger': type == 'danger'
    })
    const setSnackbar = useStoreActions(s => s.setSnackbar);

    // Clear snackbar data on interval
    setTimeout(function(e) {
        setSnackbar({})
    }, 3000)

    return <>
        <div className={ snackbarClass } style={ top?{top: top + 'px'}:{} }>
            { message }
        </div>
    </>
}