import { useStoreState, useStoreActions } from "easy-peasy";
import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import * as API from "./api";
import { WsContext, BalanceContext } from "./websocket";

const Byx88 = require("./contracts/Byx88.json");
const Byx88Payment = require("./new-contracts/Payment.json");
const Erc20 = require("./new-contracts/ERC20.json");
const TokenMock = require("./new-contracts/TokenMock.json");

// ERC20 - USDT ADDRESS
const USDT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7";

export function useMenuPadding(open) {
  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      window.addEventListener("resize", e => {
        setMenuHeight(window.innerHeight - 90);
      });
    }
    return () => {
      mounted = false;
    }
  }, []);

  const paddingNumber = useMemo(() => {
    const menuItem = document.getElementsByClassName("menu-item");
    let totalItemHeight = 0;
    if (menuItem.length) {
      totalItemHeight = menuItem.length * menuItem[0].clientHeight;
    }
    let paddingNumber = (menuHeight - totalItemHeight) / 2;
    return paddingNumber;
  }, [menuHeight, open]);

  return paddingNumber;
}

export function useMenuAction() {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen(open => !open);
  }, [])

  return [open, toggle];
}

export function useBalance(setLoading) {
  const auth = useStoreState(s => s.auth);
  const [balance, setBalance] = useState(0);
  const [dirty, setDirty] = useState(true);

  useEffect(() => {
    let mounted = true;
    if (dirty && auth) {
      setLoading(true);
      API.getBalance(auth.token)
        .then(resp => {
          if (mounted) setBalance(resp.balance);
        })
        .catch(e => console.error("useBalance", e))
        .then(() => {
          if (mounted) setLoading(false);
        });
    }
    return () => {
      mounted = false;
    }
  }, [dirty, auth]);

  const refresh = useCallback(() => setDirty(true), []);
  return [Number(balance.toFixed(2)), refresh];
}

export function useNavigate() {
  const history = useHistory();
  const navigate = useCallback((path) => history.push(path), [history]);
  return navigate;
}

export function useSiteContainerClass(open) {
  const classes = useMemo(() => {
    return "site-container " + (open ? "site-container_menu" : "")
  }, [open]);

  return classes;
}

export function formatUsername(username, format = "SSSSSSSS...EEEEEE") {
  if (username.length < format) {
    return username;
  }
  let start = 0;
  let mid = 0;
  let end = 0;

  const splitted = format.split("");
  for (const char of splitted) {
    if (char === "S") start++;
    if (char === ".") mid++;
    if (char === "E") end++;
  }
  return username.slice(0, start) + "".padStart(mid, ".") + username.slice(username.length - end, username.end);
}

export function useFormattedUsernameHelper(username, format = "SSSSSSSS...EEEEEE") {
  const uname = useMemo(() => {
    return formatUsername(username, format);
  }, [username, format]);
  return uname;
}

export function useFormattedUsername(format = "SSSSSSSS...EEEEEE") {
  const auth = useStoreState(s => s.auth);
  const account = useStoreState(s => s.account);

  const uname = useMemo(() => {
    if (!auth) return "";
    const username = account;
    return formatUsername(username, format);
  }, [auth, format]);

  return uname;
}

const networks = [
  "5777", //local 
  "3", //dev 
  "1", // mainnet
  "42", // kovan
  "2", //prod
];

const selectedNetId = process.env.REACT_APP_NETWORK_ID;
export function useContract(web3) {
  const contract = useMemo(() => {
    const netId = networks[selectedNetId];

    if (web3) {
      const c = new web3.eth.Contract(Byx88.abi, Byx88.networks[netId].address);
      return c || null;
    } else {
      return null;
    }
  }, [web3])
  return contract;
}

export function useContracts(web3) {
  const isBnb = useStoreState(s => s.isBnb);
  
  let contractAddress = ''
  let tokenContractAddress = ''
  let netId = ''

  if (isBnb) {
    contractAddress = process.env.REACT_APP_BSC_CONTRACT_ADDRESS
  } else {
    netId = networks[selectedNetId];
    contractAddress = process.env.REACT_APP_ETH_CONTRACT_ADDRESS
  }
  const byxContract = useMemo(() => {
    if (web3) {
      const c = new web3.eth.Contract(Byx88Payment.abi, contractAddress);
      return c || null;
    } else {
      return null;
    }
  }, [web3]);

  if (isBnb) {
    tokenContractAddress = process.env.REACT_APP_BSC_CONTRACT_ADDRESS
  } else {
    netId = networks[selectedNetId];
    tokenContractAddress = netId == "1"
    ? USDT_ADDRESS
    : TokenMock.networks[netId].address;
  }

  const tokenContract = useMemo(() => {
    if (web3) {
      const c = new web3.eth.Contract(TokenMock.abi, tokenContractAddress);
      // const c = new web3.eth.Contract(TokenMock.abi, TokenMock.networks[netId].address);
      return c || null;
    } else {
      return null;
    }
  }, [web3]);

  const web3Contract = useMemo(() => {
    if (web3) {
      const c = new web3.eth.Contract(Erc20.abi, tokenContractAddress);
      return c || null;
    } else {
      return null;
    }
  }, [web3, isBnb]);

  const balanceContract = useMemo(() => {
    let contract = ''
    if(isBnb) {
      contract = process.env.REACT_APP_BSC_CONTRACT_ADDRESS
    } else {
      contract = process.env.REACT_APP_ETH_CONTRACT_ADDRESS
    }

    if (web3) {
      const c = new web3.eth.Contract(TokenMock.abi, contract);
      return c || null;
    } else {
      return null;
    }
  }, [web3, isBnb]);

  return [byxContract, tokenContract, contractAddress, balanceContract, web3Contract];
}

export function useWS() {
  const ws = useContext(WsContext);
  return ws;
}

export function useBalanceStream() {
  const balance = useContext(BalanceContext);
  return balance;
}

export function copyText(data){
  navigator.clipboard.writeText(data).then(function() {
    alert("Address has been copied");
  }, function(e) {
    alert(e);
  });
}
