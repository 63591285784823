import React, { useCallback, useState } from "react";
import { useMenuPadding, useNavigate } from "../hooks";
import { useStoreState } from "easy-peasy";

const menus = [
  {
    img: "/img/icon/home.png",
    title: "Home",
    path: "/",
  },
  {
    img: "/img/icon/deposit.png",
    title: "Wallet",
    path: "/wallet",
  },
  {
    title: "Casino",
    img: "/img/icon/casino.png",
    path: "/?type=casino"
  },
  {
    title: "Slot",
    img: "/img/icon/slot.png",
    path: "/?type=slot"
  },
  {
    title: "RnG",
    img: "/img/icon/games.png",
    path: "/?type=rng"
  },
  {
    title: "Fishing",
    img: "/img/icon/fishing.png",
    path: "/?type=fishing"
  },
  {
    img: "/img/icon/report.png",
    title: "Report",
    path: "/report",
  },
  {
    img: "/img/icon/promotion.png",
    title: "Promotion",
    path: "/promotion",
  },
  {
    img: "/img/icon/faq.png",
    title: "FAQ",
    path: "/faq",
  },
]

export function MenuActions({ open }) {

  const padding = useMenuPadding(open);
  const navigate = useNavigate();
  const words = useStoreState(state => state.words);

  const getInTouchAction = useCallback(() => {
    window.open("https://t.me/");
  }, []);

  return <>
    <div className={(open ? "menu menu_opened" : "menu")} style={{ paddingTop: padding, paddingBottom: padding }}>
      {
        menus.map(m => (
          <div className="menu-item" key={m.title} onClick={() => navigate(m.path)}>
            <img src={m.img} alt={m.title} className="menu-item__img" />
            <p className="menu-item__text">{words ? words.filter(a => a.id == m.title.toLowerCase()).map(a => a.word).toString() : m.title}</p>
          </div>
        ))
      }
    </div>
    <div className="action" style={{
      display: "none"
    }}>
      <button className="action__button" onClick={() => getInTouchAction()}>
        <div className="action__button-content">
          <img src="/img/icon/telegram.png" alt="Telegram" className="action__button-icon" />
          <span className="action__button-text">GET IN TOUCH!</span>
        </div>
      </button>
      <div className="action__bottom"></div>
    </div>
  </>
}