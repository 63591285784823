import React from "react";
import { BackNav } from "../components/back-nav";
import { BottomNav } from "../components/bottom-nav";
import '../assets/scss/policy.scss'; 
import {Footer} from "../components/footer";
import { useStoreState } from "easy-peasy";

export function PrivacyPage() {
  const words = useStoreState(state => state.words);

  return <>
    <div className="container container--light container--bottom-nav">
      <BackNav title={( words ? words.filter(a => a.id === 'privacy_policy').map(a => a.word).toString() : 'Privacy Policy' )}/>
        <div className="policy">
          <div dangerouslySetInnerHTML={{__html: (words ? words.filter(a => a.id === 'policy_content').map(a => a.word)[0].join('') : 'Policy Content') }} />
        </div>
      <Footer />
      <BottomNav />
    </div>
  </>
}