import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useBalanceStream, useNavigate  } from "../hooks";
import '../assets/scss/components/home-nav.scss'; 
import WalletConnectProvider from '@walletconnect/web3-provider'
import { Snackbar } from "./snackbar";

export function HomeNav() {
    const navigate = useNavigate();
    const lang = useStoreState(state => state.lang);
    const setLang = useStoreActions((actions) => actions.setLang);
    const balanceStream = useBalanceStream();
    const isBrowser = useStoreState(s => s.isBrowser);
    const setIsEth = useStoreActions(s => s.setIsEth);
    const setIsBnb = useStoreActions(s => s.setIsBnb);
    const setWeb3 = useStoreActions(s => s.setWeb3);
    const setIsWeb3 = useStoreActions(s => s.setIsWeb3);
    const setProvider = useStoreActions(s => s.setProvider);
    const Web3 = require("web3");
    const setAccount = useStoreActions(s => s.setAccount);
    const setUsername = useStoreActions(s => s.setUsername);
    const snackbar = useStoreState(s => s.snackbar);
  
    // BSC - 56 main , 86 main (android), 97 testnet
    // ETH - 1 main , 3 ropsten, 4 rinkeby, 42 kovan, 5 gorli
    const bsc = [56, 85, 97]
    const eth = [1, 3, 4, 42, 5]

    let provider = null;
    let web3 = null

    async function getWeb3ChainId() {
        await web3.eth
          .getChainId()
          .then(function (chainId) {
            if(bsc.includes(chainId)) {
              setIsBnb(true)
            } else if(eth.includes(chainId)){
              setIsEth(true)
            }
          })
          .catch(function (e) {
            console.log(e)
          })
    }
    
    function subscribeEvent() {
        provider.on('accountsChanged', (accounts) => {
            setAccount(accounts[0])
            setUsername(accounts[0])
            // this.getWeb3Balance()
        })

        // Subscribe to chainId change
        provider.on('chainChanged', (chainId) => {
            const id = web3.utils.toBN(chainId).toString()
            if(bsc.includes(id)) {
                setIsBnb(true)
            } else if(eth.includes(id)){
                setIsEth(true)
            }
            
            // this.getWeb3Balance()
        })

        // Subscribe to session disconnection
        provider.on('disconnect', (code, reason) => {
            console.log(code, reason)
        })
    }

    function walletConnect() {
        provider = new WalletConnectProvider({
            infuraId: '4ec235cb31904878b523ec43e3f4f339',
        })
        setProvider(provider)
        setIsWeb3(true)
        setWeb3(new Web3(provider))
  
        provider
          .enable()
          .then(async (accounts) => {
            setAccount(accounts[0])
            setUsername(accounts[0])

            await getWeb3ChainId()
            // this.initDefaultToken()
            // this.getWeb3Balance()
            subscribeEvent()
          })
          .catch((e) => {
            console.log(e)
          })
    }

    return <>
        <div className="home-nav">
            <div className="home-nav__language">
                <img src={`/img/flag/${lang}.png`} alt={`language-${lang}`} /> 
                <div className="home-nav__lang-text">{ lang }</div>
                <select onChange={(e) => setLang(e.target.value)} value={lang}>
                    <option value="en">English</option>
                    <option value="cn">简体中文</option>
                </select>
            </div>
            <div className="home-nav__logo" onClick={() => navigate('/')}>
                <img src="/img/logo.png" alt="logo" />
            </div>
            <div className="home-nav__balance-wrapper">
                { isBrowser ? (
                    <button className="btn btn--small btn--yellow" onClick={() => { walletConnect() }}>Connect</button>
                ) : (
                    <div className="home-nav__balance" onClick={() => { navigate('/wallet') }}>
                        <img className="home-nav__coin-icon" src="/img/icon/coin.png" alt="coin" />
                        { balanceStream.toFixed(2) }
                    </div>
                ) }
            </div>
            { snackbar.message && <Snackbar message={snackbar.message} type={snackbar.type} top="55"  /> }
        </div>
    </>
}