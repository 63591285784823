/**
 * API access
 */
import axios from "axios";
import format from "date-fns/format";

export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = "https://api.byx88.co";
// export const API_URL = "http://localhost:3300";
// export const API_URL = "http://localhost:3000";

export const WS_URL = API_URL.includes("https") ? API_URL.replace("https", "wss") : API_URL.replace("http", "ws");

export async function getBalance(token) {
  const resp = await axios.get(API_URL + "/balance", { headers: { Authorization: token } });
  resp.data.balance = resp.data.deducted || resp.data.balance;
  return resp.data;
}

export async function verifyToken(token) {
  return true;
}

export async function getNonce(address) {
  const resp = await axios.get(API_URL + "/nonce/" + address);
  return resp.data.nonce;
}

export async function loginWallet(address) {
  const resp = await axios.post(API_URL + "/wallet-login", { address, signature: '' });
  return resp.data;
}

export async function getGames(page, type) {
  const resp = await axios.get(API_URL + "/game", {
    params: { page, type },
  });
  return resp.data;
}

export async function getProfile(token) {
  const resp = await axios.get(API_URL + "/user/one", { headers: { Authorization: token } });
  return resp.data;
}

export async function acceptTnc(token) {
  const resp = await axios.put(API_URL + "/user", {}, { headers: { Authorization: token } });
  return resp.data;
}

export async function launchGame(gameId, token, lang) {
  const resp = await axios
    .post(API_URL + "/game", { gameId, lang }, { headers: { Authorization: token } } );
  return resp.data;
}

export async function disconnectFromBaison(address, token) {
  const resp = await axios.get(API_URL + "/remove-baison-connection/" + address, { headers: { Authorization: token } });
  return resp.data;
}

export async function getDeposit(token) {
  const resp = await axios.get(
    API_URL + "/deposit/oneplayer",
    {
      params: { ...getThisWeek() },
      headers: { Authorization: token }
    }
  );
  return resp.data.map(d => {
    return ({
      date: new Date(d.created_at),
      amount: d.cnyAmount,
      status: d.paid == 1 ? "Finished" : "Pending"
    })
  });
}

export async function getWithdraw(token) {
  const resp = await axios.get(
    API_URL + "/withdraw/oneplayer",
    {
      params: { ...getThisWeek() },
      headers: { Authorization: token }
    }
  );
  console.log(resp.data);
  return resp.data.map(d => {
    return ({
      date: new Date(d.created_at),
      amount: d.cnyAmount,
      status: d.status
    })
  });
}

export async function sendWithdraw(address, amount, networkType, token) {
  const tw = getThisWeek();
  console.log(address, amount)
  const resp = await axios.post(API_URL + "/withdraw", {
    sender: address,
    username: address,
    networkType,
    amount: Number(amount),
    ...tw
  }, { headers: { Authorization: token } })

  return resp.data
}


export async function getReport(tab, token, period) {
  const PERIOD_MONTHLY = '2'
  const PERIOD_WEEKLY = '1'

  let duration = {}
  if (period.toString() === PERIOD_MONTHLY) {
    duration = getThisMonth()
  } else if(period.toString() === PERIOD_WEEKLY){
    duration = getThisWeek();
  }
  else {
    duration = getToday();
  }

  if (tab === "in-game") {
    const resp = await axios.get(API_URL + "/transaction/in-game", {
      params: { ...duration },
      headers: { Authorization: token }
    });

    return resp.data;
  } else {
    const resp = await axios.get(API_URL + "/transaction/wallet", {
      params: { ...duration },
      headers: { Authorization: token }
    });

    return resp.data;
  }

  // if (tab === "in-game") {
  //   return [
  //     { date: new Date().toLocaleString(), amount: -100, action: "In Game" },
  //     { date: new Date().toLocaleString(), amount: -150, action: "In Game" },
  //   ]
  // } else {
  //   return [
  //     { date: new Date().toLocaleString(), amount: 100, action: "Deposit" },
  //     { date: new Date().toLocaleString(), amount: -150, action: "Withdraw" },
  //     { date: new Date().toLocaleString(), amount: 100, action: "Deposit" },
  //     { date: new Date().toLocaleString(), amount: -150, action: "Withdraw" },
  //   ]
  // }
}

/**
 * 
 * @param {Date} date 
 */
function formatDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = (date.getDate()).toString().padStart(2, "0");
  return year + "-" + month + "-" + day;
}

function getToday() {
  var start = new Date();
  var end = new Date();
  return {
    startDate: `${formatDate(start)} 00:00:00`,
    endDate: `${formatDate(end)} 23:59:59`
  }
}

function getThisWeek() {
  const now = Date.now();
  const oneDay = 864e5; //javascript number that represent the number of milisecond in 24h ( 1000*60*60*24 or 86400000 or 864e5 )
  const sixDay = oneDay * 6;
  const past = new Date(now - sixDay);
  const tomorrow = new Date(now + oneDay);
  return {
    startDate: formatDate(past),
    endDate: formatDate(tomorrow)
  }
}

function getThisMonth() {
  const now = Date.now();
  const oneDay = 864e5;
  const thirtyDay = oneDay * 30;
  const past = new Date(now - thirtyDay);
  const tomorrow = new Date(now + oneDay);
  return {
    startDate: formatDate(past),
    endDate: formatDate(tomorrow)
  }
}

export function getThisWeekFormatted() {
  const data = getThisWeek();
  return {
    // start: new Date(data.startDate).toDateString(),
    // end: new Date(data.endDate).toDateString()
    start: format(new Date(data.startDate), "dd MMM yyyy"),
    end: format(new Date(data.endDate), "dd MMM yyyy")
  }
}

export async function pay(token, amount) {
  const resp = await axios.post(API_URL + "/pay", { amount: Number(amount) }, { headers: { Authorization: token } });
  return resp.data;
}

export async function updPay(token, ref){
  const resp = await axios.put(API_URL + "/pay", { ref: ref }, { headers: { Authorization: token } });
  return resp.data; 
}

export async function paymentV2(token, payload){
  const resp = await axios.post(API_URL + "/v2/pay", payload, { headers: { Authorization: token } });
  return resp.data;
}

export async function triggerDeposit(token, amountInWei) {
  const resp = await axios.post(API_URL + "/triggerdeposit", { weiAmount: amountInWei }, { headers: { Authorization: token } });
  return resp.data;
}

export async function getSliders(lang) {
  const resp = await axios.get(API_URL + "/slider", { params: { lang: lang } });
  return resp.data;
}

export async function logToDiscord(message) {
  const resp = await axios.post("https://discord.com/api/webhooks/814408260332814376/cmF4mlfQubsw6J0D31RGDjoVRykirVgFIzJ9bozmlhdp3681OQyGShYLDYSjMzYfu5U5", {
    content: message,
  });
  return resp.data;
}

export async function getFaq(token, lang) {
  const resp = await axios.get(API_URL + "/faq", { params: { lang },headers: { Authorization: token } });
  return resp.data;
}

export async function getRate(token) {
  const resp = await axios.get(
    API_URL + "/rate",
    {
      headers: { Authorization: token }
    }
  );
  return resp.data
}

export async function checkManualBalance(token) {
  const resp = await axios.post(
    API_URL + "/check-manual-balance", {}, { headers: { Authorization: token } }
  );
  return resp.data
}