import React, { useState, useCallback } from "react";
import { useStoreState } from "easy-peasy";
import { BackNav } from "../components/back-nav";
import { useFormattedUsername, useBalanceStream, copyText, useNavigate } from "../hooks";
import { BottomNav } from "../components/bottom-nav";
import { Footer } from "../components/footer";
import classNames from 'classnames'
import * as Api from "../api";
import { Modal } from "../components/modal";

import '../assets/scss/profile.scss'; 

export function ProfilePage() {
  const auth = useStoreState(s => s.auth);
  const username = useFormattedUsername();
  const balanceStream = useBalanceStream();
  const navigate = useNavigate();
  const words = useStoreState(state => state.words);

  const [modalShow, setModalShow] = useState(false);
  const isBnb = useStoreState(state => state.isBnb);
  const isTron = useStoreState(state => state.isTron);
  const account = useStoreState(state => state.account);

  const closeModal = useCallback(() => {
    setModalShow(false)
  })

  let scanProvider = 'ETHScan'
  let linkProvider = process.env.REACT_APP_ETH_HOST
  if (isBnb) {
    scanProvider = 'BSCScan'
    linkProvider = process.env.REACT_APP_BSC_HOST
  } else if(isTron) {
    scanProvider = 'TRONScan'
    linkProvider = process.env.REACT_APP_TRON_HOST
  }

  const modalContent = (
    auth &&
    (<div className="profile__modal-content">
      <div className="profile__modal-address">{ account }</div>
      <div className="profile__modal-action">
        <div className="profile__action-wrapper" onClick={() => {
          window.open(linkProvider + 'address/' + account, '_blank');
        }}>View on { scanProvider }&nbsp;<img className="profile__icon-redirect" src="img/icon/redirect.png" /></div>
        <div className="profile__action-wrapper" onClick={() => {
          copyText(account);
        }}>{ words ? words.filter(a => a.id === 'copy_address').map(a => a.word).toString() : 'Copy Address' }&nbsp;<img className="profile__icon-copy" src="img/icon/copy.png"></img></div>
      </div>
    </div> )
  )

  return <>
    <div className="container container--light container--bottom-nav">
      { modalShow && (<Modal title={ words ? words.filter(a => a.id === 'your_wallet').map(a => a.word).toString() : 'Your Wallet' } body={ modalContent } close={ closeModal } />) }
      <BackNav title={ words ? words.filter(a => a.id === 'account').map(a => a.word).toString() : 'Account' } />
      <div className="content profile">
        <div className="card profile__item card--action">
          <div className="card__title profile__wallet">
            <div className="profile__account">
              <span onClick={() => { setModalShow(true); }} >{ `...${username.slice(-6)}` }</span>
              <img src="img/icon/copy.png" className="profile__copy-icon" alt="copy" onClick={() => {
                  copyText(account);
                }}>
              </img>
            </div>
            <div className="profile__balance">
              <span className="profile__amount">{ balanceStream.toFixed(2) } </span>
            </div>
          </div>
          <div className="card__separator" />
          <div className="card__content">
            <ul className="profile__menu">
                <li onClick={() => { navigate('/wallet') }}>
                  <img src="img/report/deposit.png"/>
                  <div>{ words ? words.filter(a => a.id === 'deposit').map(a => a.word).toString() : 'Deposit' }</div>
                </li>
                <li onClick={() => { navigate('/wallet') }}>
                  <img src="img/report/withdrawal.png"/>
                  <div>{ words ? words.filter(a => a.id === 'withdrawal').map(a => a.word).toString() : 'Withdrawal' }</div>
                </li>
                <li onClick={() => { navigate('/report/bet') }}>
                  <img src="img/report/bet_record.png"/>
                  <div>{ words ? words.filter(a => a.id === 'bet_record').map(a => a.word).toString() : 'Bet Record'}</div>
                </li>
                <li onClick={() => { navigate('/report/transaction') }}>
                  <img src="img/report/trans_record.png"/>
                  <div>{ words ? words.filter(a => a.id === 'transaction_report').map(a => a.word).toString() : 'Transaction Report' }</div>
                </li>
              </ul>
          </div>
        </div>

        <div className="card profile__item" onClick={() => {
          navigate('/faq')
        }}>
          <div className="card__content profile__link">
            <i className="profile__faq-icon" />
            { words ? words.filter(a => a.id === 'faq').map(a => a.word).toString() : 'FAQ' }
          </div>
        </div>

        <div className="card profile__item profile__with-border" onClick={() => {
          navigate('/about')
        }}>
          <div className="card__content profile__link">
            <i className="profile__about-icon" />
            { words ? words.filter(a => a.id === 'about_us').map(a => a.word).toString() : 'About Us' }
          </div>
        </div>
      </div>
      <Footer />
      <BottomNav />
    </div>
  </>
}