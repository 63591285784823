import React, { useCallback, useState, useEffect } from "react";
import classNames from 'classnames'
import * as Api from "../../api";
import { useStoreState } from "easy-peasy";
import { BackNav } from "../../components/back-nav";
import { BottomNav } from "../../components/bottom-nav";
import format from "date-fns/format";
import { Footer } from "../../components/footer";

import '../../assets/scss/report.scss'; 

export function ReportBettingPage() {
  const auth = useStoreState(s => s.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState(0);
  const words = useStoreState(state => state.words);

  function CountTotalBet(dt) {
    let res = { row: 0, total: 0 }
    data.filter(function(e) {
      return (new Date(e.date).toDateString() === new Date(dt.date).toDateString())
    }).forEach(function(e) {
      res.row +=1;
      res.total += e.amount;
    })
    return res;
  }

  function HeaderFilter() {
    return (
      <div className="back-nav__filter">
        <div className="custom-select">
          <select onChange={(e) => (setPeriod(e.target.value),fetch.bind(e.target.value))} value={period}>
            <option value="0">{ words ? words.filter(a => a.id === 'today').map(a => a.word).toString() : 'Today' }</option>
            <option value="1">{ words ? words.filter(a => a.id === 'weekly').map(a => a.word).toString() : 'Weekly' }</option>
            <option value="2">{ words ? words.filter(a => a.id === 'monthly').map(a => a.word).toString() : 'Monthly' }</option>
          </select>
        </div>
      </div>
    );
  }
  
  const fetch = useCallback( (period) => {
    if (auth) {
      setLoading(true);
      (() => {
        return Api.getReport('in-game', auth.token, (period));
      })()
      .then(e => { console.log(e); return e })
      .then(res => setData(res))
      .catch(console.error)
      .then(() => setLoading(false)); 
    }
    return () => false;
  }, [auth])

  useEffect(() => {
    if (auth) {
      fetch(period)
    }
  }, [auth, period]);

  return <>
    <div className="container container--light container--bottom-nav container--with-filter report">
      <BackNav title="Bet Report" filter={ <HeaderFilter /> } />
      
      <div className="content">
        {
          loading
            ? <center className="single-message"><b>{ ( words ? words.filter(a => a.id === 'loading').map(a => a.word).toString() : 'Loading...' ) }</b></center>
            : data.map((dt, i) =>  
              {
                const condition = i === 0 || (i > 0 && (new Date(data[i-1].date).toDateString() !== new Date(dt.date).toDateString()))
                const dateLabel = (condition) ? (
                  <div className="report__filter" key={`date-${i}`}>
                    <div>{ format(new Date(dt.date),'dd MMM yyyy') }</div>
                    <div>{ `Row: ${CountTotalBet(dt).row} Win/Loss: ${CountTotalBet(dt).total}` }</div>
                  </div>) : ''

                let cardClass = classNames({
                  'card': true,
                  'card--round': true,
                  'bet-item': true,
                })

                let amountClass = classNames({
                  'bet-item__amount': true,
                  'bet-item__amount--minus': dt.amount < 0,
                })

                return (<React.Fragment key={`trx-${i}`}>
                  { dateLabel }
                  <div className={ cardClass } key={`bet-${i}`}>
                    <div className="card__title bet-item__title">{ dt.action }</div>
                    <div className="card__separator" />
                    <div className="card__content bet-item__content">
                      <div className="bet-item__detail">
                        <div className="bet-item__type">
                          { dt.game }
                          <div className="bet-item__date">
                            { format(new Date(dt.date),'HH:mm:ss') }
                          </div>
                        </div>
                        <div className={ amountClass }>{ Math.floor(dt.amount * 1e2) / 1e2  }</div>
                      </div>
                      
                    </div>
                  </div>
                </React.Fragment>)
              }
            )
        }
        {
          (!loading && !data.length) && <center className="single-message"><b>{ words ? words.filter(a => a.id === 'no_data').map(a => a.word).toString() : 'No Available Data' }</b></center>
        }
      </div>
      <Footer />
      <BottomNav />
    </div>
  </>
}