import { StoreProvider, useStoreState, useStoreActions } from 'easy-peasy';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { AppStore } from './store';
import './App.css';

import { Web3Context } from "./web3-context";
import React, { useEffect, useRef, useState, useContext } from 'react';
import { HomePage } from './pages/home';
import { PromotionPage } from './pages/promotion';
import { PromotionDetailPage } from './pages/promotion-detail';
import { WalletPage } from './pages/wallet';
import { FaqPage } from './pages/faq';
import { ReportTransactionPage } from './pages/report/transaction';
import { ReportBettingPage } from './pages/report/betting';
import { ProfilePage } from './pages/profile';
import { AboutPage } from './pages/about';
import { NotFoundPage } from './pages/404';
import { TermsAndConditions } from "./pages/termsAndConditions";
import { PrivacyPage } from "./pages/privacy";
import * as  Api from "./api";
import { BalanceContext, WS, WsContext } from './websocket';
import { ToastProvider, useToasts } from 'react-toast-notifications'
import ScrollIntoView from "./components/scrollIntoView";

const Web3 = require("web3");

const routes = [
  {
    path: "/",
    exact: true,
    component: HomePage
  },
  {
    path: "/wallet",
    exact: true,
    component: WalletPage
  },
  {
    path: "/promotion/",
    exact: true,
    component: PromotionPage
  },
  {
    path: "/faq",
    exact: true,
    component: FaqPage
  },
  {
    path: "/report/transaction",
    exact: true,
    component: ReportTransactionPage
  },
  {
    path: "/report/bet",
    exact: true,
    component: ReportBettingPage
  },
  {
    path: "/promotion/:id",
    exact: false,
    component: PromotionDetailPage
  },
  {
    path: "/profile",
    exact: false,
    component: ProfilePage
  },
  {
    path: "/about",
    exact: false,
    component: AboutPage
  },
  {
    path: "/termsAndConditions",
    exact: false,
    component: TermsAndConditions
  },
  {
    path: "/privacy",
    exact: false,
    component: PrivacyPage
  },
];

function RoutingWrapped() {
  const [account, setAccount] = useState(null);
  const setAuth = useStoreActions(s => s.setAuth);
  const token = window.localStorage.getItem("token");
  const username = window.localStorage.getItem("username");
  const getDate = new Date();

  useEffect(() => {
    if (token && username && username == account) {
      console.log("SET AUTH");
      setAuth(JSON.parse(token));
    }
  }, [token, username, account]);

  return <div>
    <Switch>
      {
        routes.map(r => (
          <Route key={r.path} path={r.path} exact={r.exact}>
            <r.component />
          </Route>
        ))
      }
      <Route path={"*"} component={NotFoundPage} />
    </Switch>
  </div>
}

function App() {
  const auth = useStoreState(s => s.auth);
  const setSnackbar = useStoreActions(s => s.setSnackbar);
  const [ws, setWs] = useState(null);
  const [wsOpen, setWsOpen] = useState(false);

  const [balance, setBalance] = useState(0);
  const { addToast } = useToasts();

  const setAccount = useStoreActions(s => s.setAccount);
  const setUsername = useStoreActions(s => s.setUsername);
  const setIsWeb3 = useStoreActions(s => s.setIsWeb3);
  const setIsEth = useStoreActions(s => s.setIsEth);
  const setIsBnb = useStoreActions(s => s.setIsBnb);
  const setIsTron = useStoreActions(s => s.setIsTron);
  const setIsBrowser = useStoreActions(s => s.setIsBrowser);
  const setWeb3 = useStoreActions(s => s.setWeb3);
  const setTronWeb = useStoreActions(s => s.setTronWeb);
  const setProvider = useStoreActions(s => s.setProvider);
  const setAuth = useStoreActions(s => s.setAuth);

  // BSC - 56 main , 86 main (android), 97 testnet
  // ETH - 1 main , 3 ropsten, 4 rinkeby, 42 kovan, 5 gorli
  const bsc = [56, 86, 97]
  const eth = [1, 3, 4, 42, 5]
  
  let provider = null
  let web3 = null

  // New Provider Function

  async function loginProvider(acc) {
    Api.loginWallet(acc)
      .then((res) => {
          setAuth(res);
      })
      .catch((err) => {
        console.error('err', err);
      })
  }

  async function getWeb3ChainId() {
    await web3.eth
      .getChainId()
      .then(function (chainId) {
        if(bsc.includes(chainId)) {
          setIsBnb(true)
        } else if(eth.includes(chainId)){
          setIsEth(true)
        }
      })
      .catch(function (e) {
        console.log(e)
      })
  }

  function subscribeEvent() {
    provider.on('accountsChanged', (accounts) => {
      const checksumAddress = web3.utils.toChecksumAddress(accounts[0])
      setAccount(checksumAddress)
      setUsername(checksumAddress)
      
      // this.getWeb3Balance()
    })

    // Subscribe to chainId change
    provider.on('chainChanged', (chainId) => {
      const id = web3.utils.toBN(chainId).toString()
      if(bsc.includes(id)) {
        setIsBnb(true)
      } else if(eth.includes(id)){
        setIsEth(true)
      }
      
      // this.getWeb3Balance()
    })

    // Subscribe to session disconnection
    provider.on('disconnect', (code, reason) => {
      console.log(code, reason)
    })
  }

  function initDapp() {
    setIsWeb3(true)
    setProvider(window.ethereum)
    setWeb3(new Web3(provider))
    provider = window.ethereum
    web3 = new Web3(provider)
    provider
      .request({ method: 'eth_requestAccounts' })
      .then(async function (accounts) {
        const checksumAddress = web3.utils.toChecksumAddress(accounts[0])
        setAccount(checksumAddress)
        setUsername(checksumAddress)
        loginProvider(checksumAddress)

        await getWeb3ChainId()
        // $self.initDefaultToken()
        // $self.getWeb3Balance()
        subscribeEvent()
      })
      .catch(function (e) {
        console.log(e, 'err')
      })
  }

  async function initTronWeb() {
    setIsTron(true)
    setTronWeb(window.tronWeb)
    const defaultAddress = await window.tronWeb.defaultAddress.base58
    setAccount(defaultAddress || '')
    setUsername(defaultAddress || '')
    loginProvider(defaultAddress || '')
    // this.initDefaultToken()
    // if (this.account) {
    //   this.getTronBalance()
    // }
  }

  function checkDapp() {
    // Wait for tronweb to be injected for 1s max
    setTimeout(function () {
      if (window.tronWeb) {
        initTronWeb()
      } else if (window.ethereum) {
        initDapp()
      } else {
        setIsBrowser(true)
      }
    }, 1000)
  }
  // New Provider Function

  useEffect(() => {
    checkDapp()
  }, []);
  
  useEffect(() => {
    if (auth) {
      setWs(new WS(Api.WS_URL + "/events-cron?token=" + auth.token, {
        openChange: (o) => {
          setWsOpen(o);
        }
      }))
    };
  }, [auth]);

  useEffect(() => {
    if (ws) {

      // register listeners
      const balanceSub = ws.addListener("balance", e => {
        setBalance(e.data.balance);
      });

      const paymentSub = ws.addListener("payment-status", e => {
        setSnackbar({message: 'Deposit has been received'})
        ws.send("balance", {});
      });

      ws.connect();

      return () => {
        ws.removeListener(balanceSub);
        ws.removeListener(paymentSub);
      }
    };
  }, [ws]);

  useEffect(() => {
    if (wsOpen) {
      ws.send("balance", {});
      ws.send("payment-status", {});
    }
  }, [wsOpen]);

  return (
    <WsContext.Provider value={ws}>
      <BalanceContext.Provider value={balance}>
        <Router>
          <ScrollIntoView>
            <RoutingWrapped />
          </ScrollIntoView>
        </Router>
      </BalanceContext.Provider>
    </WsContext.Provider>
  );
}


function getLibrary(provider) {
  const library = new Web3(provider);
  return library;
}

function ethEnabled() {
  if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
    window.ethereum.enable();
    return true;
  }
  return false;
}

function StoreWrap() {
  const web3 = useRef(getLibrary(Web3.givenProvider));

  console.log(Web3.givenProvider)

  // useEffect(() => {
  //   if (web3) ethEnabled();
  // }, [web3.current]);

  return <Web3Context.Provider value={web3.current}>
    <StoreProvider store={AppStore}>
      <ToastProvider>
        <App />
      </ToastProvider>
    </StoreProvider>
  </Web3Context.Provider>
}

export default StoreWrap;
