import React from "react";
import { useNavigate } from "../hooks";
import { Snackbar } from "./snackbar";
import { useStoreState } from 'easy-peasy';
import '../assets/scss/components/back-nav.scss'; 

export function BackNav(props) {
    const { title, filter, route } = props;
    const navigate = useNavigate();
    const snackbar = useStoreState(s => s.snackbar);

    return <>
        <div className="back-nav">
            <div className="back-nav__container">
                <div className="back-nav__action" onClick={() => navigate('/')}>
                    <i className="back-nav__back-icon" />
                </div>
                <div className="back-nav__title" onClick={ () => route ? navigate(route) : () => false } >
                    { title }
                    { route ? <i className="back-nav__navigate-icon" /> : '' }
                </div>
            </div>
            <div className="back-nav__slot">
                { filter }
            </div>
            { snackbar.message && <Snackbar message={snackbar.message} type={snackbar.type} /> }
        </div>
    </>
}