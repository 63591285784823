import React from "react";
import '../assets/scss/components/modal.scss'; 

export function Modal(props) {
    const { title, body, close } = props;

    return <>
        <div className="overlay" />
        <div className="modal">
          <div className="modal__title">
            <span>{ title }</span>
            <img className="modal__close" src="img/icon/close-red.png" onClick={close} />
          </div>
          <div className="modal__separator" />
          <div className="modal__content">
            { body }
          </div>
        </div>
    </>
}