import React, { useCallback, useState, useEffect } from "react";
import classNames from 'classnames'
import * as Api from "../../api";
import { useStoreState } from "easy-peasy";
import { BackNav } from "../../components/back-nav";
import { BottomNav } from "../../components/bottom-nav";
import { Footer } from "../../components/footer";
import format from "date-fns/format";

import '../../assets/scss/report.scss'; 

export function ReportTransactionPage() {
  const auth = useStoreState(s => s.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [type, setType] = useState('');
  const [period, setPeriod] = useState(0);
  const words = useStoreState(state => state.words);

  function HeaderFilter() {
    return (
      <div className="back-nav__filter">
        <div className="custom-select">
          <select onChange={(e) => setType(e.target.value)} value={type}>
            <option value="">{ words ? words.filter(a => a.id === 'all').map(a => a.word).toString() : 'All' }</option>
            <option value="Deposit">{ words ? words.filter(a => a.id === 'deposit').map(a => a.word).toString() : 'Deposit' }</option>
            <option value="Withdraw">{ words ? words.filter(a => a.id === 'withdrawal').map(a => a.word).toString() : 'Withdrawal' }</option>
          </select>
        </div>
        <div className="custom-select">
          <select onChange={(e) => (setPeriod(e.target.value),fetch.bind(e.target.value))} value={period}>
          <option value="0">{ words ? words.filter(a => a.id === 'today').map(a => a.word).toString() : 'Today' }</option>
            <option value="1">{ words ? words.filter(a => a.id === 'weekly').map(a => a.word).toString() : 'Weekly' }</option>
            <option value="2">{ words ? words.filter(a => a.id === 'monthly').map(a => a.word).toString() : 'Monthly' }</option>
          </select>
        </div>
      </div>
    );
  }
  
  const fetch = useCallback( (period) => {
    if (auth) {
      setLoading(true);
      (() => {
        return Api.getReport('wallet', auth.token, (period));
      })()
      .then(e => { console.log(e); return e })
      .then(res => setData(res))
      .catch(console.error)
      .then(() => setLoading(false)); 
    }
    return () => false;
  }, [auth])

  const filteredData = data.filter((dt) => (!type || dt.action === type))

  const getTransactionStatus = (status) => {
    switch (status) {
      case 2:
        return 'Failed'
      case 1:
        return 'Success'
      case 0:
        return 'Pending'
      default:
        return 'Failed'
    }
  }

  useEffect(() => {
    if (auth) {
      fetch(period)
    }
  }, [auth, period]);

  return <>
    <div className="container container--light container--bottom-nav container--with-filter report">
      <BackNav title={ words ? words.filter(a => a.id === 'transaction_report').map(a => a.word).toString() : 'Transaction Report' } filter={ <HeaderFilter /> } />
      
      <div className="content">
        {
          loading
            ? <center className="single-message"><b>{ ( words ? words.filter(a => a.id === 'loading').map(a => a.word).toString() : 'Loading...' ) }</b></center>
            : filteredData.map((dt, i) =>  
              {
                const condition = i === 0 || (i > 0 && (new Date(filteredData[i-1].createdAt).toDateString() !== new Date(dt.createdAt).toDateString()))
                const dateLabel = (condition) ? (<div className="report__filter">{ format(new Date(dt.createdAt),'dd MMM yyyy') }</div>) : ''
                let cardClass = classNames({
                  'card': true,
                  'card--full': true,
                  'card--no-margin': !condition,
                })

                let amountClass = classNames({
                  'transaction-item__amount': true,
                  'transaction-item__amount--minus': dt.action.toLowerCase() !== 'deposit',
                })

                return (
                <React.Fragment key={`trx-${i}`}>
                  { dateLabel }
                  <div className={ cardClass }>
                    <div className="card__content">
                      <div className="transaction-item">
                        <div className="transaction-item__img">
                          { dt.action.toLowerCase() === 'deposit' ? <img src="../img/report/deposit.png"/> : <img src="../img/report/withdrawal.png"/> }
                        </div>
                        <div className="transaction-item__content">
                          <div className="transaction-item__information">
                            <div className="transaction-item__title">{ words ? words.filter(a => a.id === dt.action.toLowerCase()).map(a => a.word).toString() : dt.action }</div>
                            <div className="transaction-item__date">{ `${format(new Date(dt.createdAt),'HH:mm:ss')} - ${getTransactionStatus(dt.status)}` }</div>
                          </div>
                          <div className="transaction-item__detail">
                            <div className={ amountClass }>{ Math.floor(dt.cnyAmount * 1e4) / 1e4 }</div>
                            <div className="transaction-item__id" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>)
              }
            )
        }
        {
          (!loading && !filteredData.length) && <center className="single-message"><b>{ words ? words.filter(a => a.id === 'no_data').map(a => a.word).toString() : 'No Available Data' }</b></center>
        }
      </div>
    </div>
    <Footer />
    <BottomNav />
  </>
}