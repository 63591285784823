import React from "react";
import { useStoreState } from "easy-peasy";
import { Link } from 'react-router-dom';

import '../assets/scss/components/footer.scss'; 

export function Footer() {
    const words = useStoreState(state => state.words);

return <>
  <footer className="footer">
    <div className="gold-border"/>
    <div className="footer__vendor-wrapper">
        <div className="footer__vendor-list">
            <img className="footer__logo footer__logo--pragmaticplay" src="/img/partner/pragmatic_play.png" alt="vendor_pragmaticplay" />
            <img className="footer__logo footer__logo--microgaming" src="/img/partner/microgaming.png" alt="vendor_microgaming" />
            <img className="footer__logo footer__logo--gambit" src="/img/partner/gambit.png" alt="vendor_gambit" />
        </div>
        <div className="footer__vendor-list">
            <img className="footer__logo footer__logo--ag" src="/img/partner/asia_gaming.png" alt="vendor_asiagaming" />
            <img className="footer__logo footer__logo--baison" id="logoBaison" src="/img/partner/baison.png" alt="vendor_baison" />
            <img className="footer__logo footer__logo--cq9" id="logoCQ" src="/img/partner/cq9.png" alt="vendor_cq9" />
        </div>
    </div>
    <div className="footer__separator" />
    <div className="footer__security">
        <div className="footer__security-group">
            <div className="footer__security-label">SECURITY & TRUST</div>
            <div className="footer__security-icon">
                <img className="logo" id="isleofman" src="/img/partner/isle_of_man_holder.png" alt="isleofman" />
                <img className="logo" src="/img/partner/gaminglabs.png" alt="gaminglabs" />
            </div>
        </div>        
        <div className="footer__security-group">
            <div className="footer__security-label">RESPONSIBLE GAMING</div>
            <div className="footer__security-icon">
                <img className="logo" src="/img/partner/over_18.png" alt="over18" />
                <img className="logo" id="gamcare_logo" src="/img/partner/gamcare.png" alt="gamcare" />
                <img className="logo" src="/img/partner/gamble_aware.png" alt="gambleaware" />
            </div>
        </div>
    </div>
    <div className="footer__operated">
        <span>This website is operated by UBObet LLC, a company registered <br/> and established under the laws of Isle of Man</span>
    </div>
    <div className="footer__tnc">
        <Link to="/termsAndConditions" className="footer__link">{ words ? words.filter(a => a.id === 'terms_conditions').map(a => a.word).toString() : 'Terms and Conditions'}</Link>
        <span>&emsp;&emsp;|&emsp;&emsp;</span>
        <Link to="/privacy" className="footer__link">{ words ? words.filter(a => a.id === 'privacy_policy').map(a => a.word).toString() : 'Privacy Policy'}</Link> 
    </div> 
  </footer>
</>
}