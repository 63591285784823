import { useStoreState } from "easy-peasy";
import { useNavigate } from "../hooks";
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import '../assets/scss/components/bottom-nav.scss'; 

export function BottomNav() {
    const words = useStoreState(state => state.words);
    const auth = useStoreState(state => state.auth);
    const navigate = useNavigate();
    const bottomMenu = [
        {
            title: "Home",
            icon: "home",
            path: "/"
        },
        {
            title: "Wallet",
            icon: "wallet",
            path: "/wallet"
        },
        {
            title: "Service",
            icon: "service",
            path: "/faq"
        },
        {
            title: "Report",
            icon: "report",
            path: "/report/transaction",
            auth: true
        },
        {
            title: "Account",
            icon: "account",
            path: "/profile",
            auth: true
        },
    ];
    const location = useLocation();
  
    return <>
        <div className="bottom-nav">
            <ul className="bottom-nav__menu">
                {
                    bottomMenu.map((menu, i) => {
                        const menuClass = classNames({
                            'bottom-nav__item': true,
                            'bottom-nav__item--active': (location.pathname === menu.path),
                        })

                        let iconClass = [
                            'bottom-nav__icon',
                            `bottom-nav__icon--${menu.icon}`
                        ]
                        iconClass = iconClass.join(' ')
                        
                        return (<li className={menuClass} onClick={() => ((menu.auth && !auth) ? navigate('/wallet') : navigate(menu.path))} key={`m-menu-${i}`}>
                            <i className={iconClass}></i>
                            {words ? words.filter(a => a.id === menu.title.toLowerCase()).map(a => a.word).toString() : menu.title}
                        </li>)
                })}
            </ul>
        </div>
    </>
}