import React from "react";
import { BackNav } from "../components/back-nav";

export function NotFoundPage() {

  return <>
    <div className="container container--light">
      <BackNav title="Not Found" />
      <div className="content">
        <center className="single-message"><b>Page Not Found</b></center>
      </div>
    </div>
  </>
}