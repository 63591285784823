import React, { useCallback, useState, useEffect, useContext } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { BackNav } from "../components/back-nav";
import '../assets/scss/wallet.scss';
import { BottomNav } from "../components/bottom-nav";
import { Footer } from "../components/footer";
import * as Api from "../api";
import { v4 } from "uuid";
import { Web3Context } from "../web3-context";
import { useContracts, useFormattedUsername, useBalanceStream } from "../hooks";
import Swal from 'sweetalert2';
const BNB = "BNB"
const ETH = "ETH"

export function WalletPage() {
  const fixDecimal = function (amount, decimal) {
    const res = (Math.floor(amount * decimal) / decimal)
    return (isNaN(res) || !res) ? '' : res;
  }

  const web3 = useContext(Web3Context);
  const [isDeposit, setIsDeposit] = useState(1);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [timerAmount, settimerAmount] = useState(null);
  const [balance, setBalance] = useState(0);
  const [rate, setRate] = useState([]);
  const auth = useStoreState(s => s.auth);
  const account = useStoreState(s => s.account);
  const isBnb = useStoreState(s => s.isBnb);
  const isTron = useStoreState(s => s.isTron);
  const words = useStoreState(state => state.words);
  const transferTitle = isDeposit ?
    (words ? words.filter(a => a.id === 'deposit').map(a => a.word).toString() : 'Deposit') :
    (words ? words.filter(a => a.id === 'withdrawal').map(a => a.word).toString() : 'Withdrawal')
  const rateBuy = (isNaN(rate.buy) ? 0 : fixDecimal(rate.buy, 1e4))
  const rateSell = (isNaN(rate.sell) ? 0 : fixDecimal(rate.sell, 1e4))
  const resultAmount = fixDecimal((isDeposit ?
    (isNaN(amount * rateBuy) ? 0 : amount * rateBuy) :
    (isNaN(amount / rateSell) ? 0 : amount / rateSell)), 1e7) || '0.0'
  const [coin, setCoin] = useState('ERC20');
  const [tronOwnerAddress, setTronOwnerAddress] = useState('');
  const [byxContract, tokenContract, contractAddress, balanceContract, web3Contract] = useContracts(web3);
  const gas = 150000;
  const username = useFormattedUsername();
  const balanceToken = fixDecimal(balance, 1e4)
  const balanceStream = fixDecimal(useBalanceStream(), 1e4)
  const creditTranslation = (words ? words.filter(a => a.id === 'credit').map(a => a.word).toString() : 'Credit')
  const creditsTranslation = (words ? words.filter(a => a.id === 'credits').map(a => a.word).toString() : 'Credits')
  const setSnackbar = useStoreActions(s => s.setSnackbar);
  const BNB = 'BNB';
  const ETH = 'ETH';
  const TRX = 'TRX';
  let intervalBalance = null
  let intervalCount = 0
  let token = 'ERC20';

  const debounceAmount = function (amount) {
    if (timerAmount) {
      clearTimeout(timerAmount);
      settimerAmount(null);
    }
    setAmount(amount)

    settimerAmount(
      setTimeout(() => {
        setAmount(fixDecimal(amount, 1e7))
      }, 500)
    );
  }

  if (isBnb) {
    token = 'BEP20'
  } else if (isTron) {
    token = 'TRC20'
  }

  // Used to get tronWeb owner address
  useEffect(async () => {
    let ownerAddress;
    if (window.tronWeb && window.tronWeb.defaultAddress && window.tronWeb.defaultAddress.base58) {
      ownerAddress = await window.tronWeb.defaultAddress.base58 || "trx address not found!";
    }
    setTronOwnerAddress(ownerAddress);
  }, [window.tronWeb]);

  function getBalance() {
    if (isTron) {
      getTronBalance()
    } else {
      getWeb3Balance()
    }
  }

  async function getTronBalance() {
    try {
      const instance = await window.tronWeb
        .contract()
        .at(process.env.REACT_APP_TRON_CONTRACT)

      instance
        .balanceOf(tronOwnerAddress)
        .call()
        .then((balance) => {
          setBalance(window.tronWeb.fromSun(balance))
        })
        .catch((e) => {
          if (intervalBalance) {
            clearTimeout(intervalBalance)
          }

          if (intervalCount < 5) {
            intervalBalance = setTimeout(function () {
              getTronBalance()
            }, 2000)
            intervalCount += 1
          }
          console.log(e);
        })
    } catch (e) {
      setSnackbar({message: e, type: 'danger'})
    }
  }

  function getWeb3Balance() {
    balanceContract.methods
      .balanceOf(account)
      .call()
      .then(function (e) {
        if (isBnb) {
          setBalance(web3.utils.fromWei(e, 'ether'))
        } else {
          setBalance(web3.utils.fromWei(e, 'mwei'))
        }
      })
      .catch(function (e) {
        if (intervalBalance) {
          clearTimeout(intervalBalance)
        }

        if (intervalCount < 5) {
          intervalBalance = setTimeout(function () {
            getWeb3Balance()
          }, 2000)
          intervalCount += 1
        }
      })
  }

  const handleTRC20Network = async (token, amount, address) => {
    const traceId = v4();

    // minimal deposit 1 USDT
    if (Number(amount) < 1) {
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid amount: ' + amount,
        footer: "Please input valid amount"
      })
    }

    Swal.fire({
      title: 'Continue payment with TRC20 network?',
      text: "Make sure your main wallet is TRX and wallet ( Imtoken or Tronlink ) is active!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Submit'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const params = {
            env: process.env.REACT_APP_ENV,
            contractAddress: process.env.REACT_APP_TRON_CONTRACT,
            address,
            tronTargetAddress: process.env.REACT_APP_TRON_USDT_TARGET_ADDRESS,
            amount,
          }

          Api.logToDiscord(JSON.stringify({
            type: "deposit-trigger",
            traceId,
            meta: "parameter",
            params
          }));
          // eslint-disable-next-line no-undef
          const contractAddressHex = tronWeb.address.toHex(process.env.REACT_APP_TRON_CONTRACT)
          // eslint-disable-next-line no-undef
          let instance = await tronWeb.contract().at(contractAddressHex);
          const args = {
            callValue: 0,
            shouldPollResponse: false
          }

          await instance
            .transfer(
              // eslint-disable-next-line no-undef
              TRON_USDT_TARGET_ADDRESS, (process.env.REACT_APP_ENV === 'development') ? amount : tronWeb.toSun(amount)
            )
            .send(args)
            .then(txId => {
              // add txId to params
              let transactionUrl = process.env.REACT_APP_TRON_HOST + 'transaction/' + txId;
              params["txId"] = txId;
              params["urlTransaction"] = transactionUrl;

              Api.paymentV2(token, {
                tronTxId: txId,
                address,
                amount: Number(amount)
              })
                .then(data => {
                  //todo log to discord
                  Api.logToDiscord(JSON.stringify({
                    type: "save-deposit-trx",
                    traceId,
                    meta: "deposit",
                    data
                  }, null, 2));
                })

              Api.logToDiscord(JSON.stringify({
                type: "deposit-trigger",
                traceId,
                meta: "allowance",
                params
              }, null, 2));

              return Swal.fire(
                'Payment is Complete!',
                'It takes 2 - 3 minutes for system to getting updated! <br /><br /> <a class="btn btn-primary" href="' + transactionUrl + '" target="_blank">See Transaction</a>',
                'success')
            })
        } catch (error) {
          Api.logToDiscord(JSON.stringify({
            type: "error",
            traceId,
            meta: "error-deposit",
            data: {
              error,
              address
            }
          }, null, 2));

          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: error
          })
        } finally {
          setLoading(false);
        }
      }
    })
  }

  // token is jwtToken, address is userAddress
  // checking token with isBnb, which is currently used for ETH or BNB
  // if ETH network family is added due to business requirement
  // need to change this code to SWITCH...CASE
  const handleEtherNetwork = async (token, amount, address) => {
    const traceId = v4();
    const params = {
      from: address,
      gas: isBnb ? 50000 : gas,
      gasPrice: web3.utils.toWei(isBnb ? "10" : "120", "gwei"),
    }

    let strAmount = web3.utils.toWei(String(amount), "ether");
    let strAmountEth = web3.utils.toWei(String(amount), "mwei");


    const discordData = {
      env: process.env.REACT_APP_ENV,
      contractAddress: contractAddress,
      targetAddress: isBnb ? process.env.REACT_APP_BSC_TARGET_ADDRESS : process.env.REACT_APP_ETH_TARGET_ADDRESS,
      fromAddress: address,
      amount: isBnb ? strAmount : strAmountEth,
      actualAmount: amount
    }

    web3Contract.methods.allowance(address, contractAddress)
      .call({ from: address })
      .then(allowance => {
        const allowanceUSDT = Number(web3.utils.fromWei(allowance, isBnb ? "ether" : "mwei"));
        if (allowanceUSDT >= Number(amount)) {
          return;
        }
        return web3Contract.methods.approve(contractAddress, web3.utils.toWei("9999999999", isBnb ? "ether" : "mwei"))
          .send(params)
          .on('error', function (error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            setSnackbar({message: "Oops.. Something happen, please contact our administrator (" + traceId + ")", type: 'danger'})
            Api.logToDiscord(JSON.stringify({
              traceId,
              type: isBnb ? "bnb-allow-error" : "eth-allow-error",
              msg: error,
              receipt,
              data: discordData
            }, null, 2))
          });
      })
      .then(e => {
        web3Contract.methods.transfer(
          isBnb ? process.env.REACT_APP_BSC_TARGET_ADDRESS : process.env.REACT_APP_ETH_TARGET_ADDRESS,
          isBnb ? strAmount : strAmountEth
        )
          .send(params)
          .on("transactionHash", hash => {
            setSnackbar({message: "Transaction is on processing (" + traceId + ")"})
            let depositData = {
              txId: hash,
              amount,
              walletType: isBnb ? BNB : ETH // todo make constanta | hardcode for a moment
            }

            // on transaction call api
            // todo set failed status
            // 0 open, 1 success, 2 pending, 3 failed
            Api.paymentV2(token, depositData)
              .then(deposit => {
                //todo log to discord
                Api.logToDiscord(JSON.stringify({
                  type: isBnb ? "bnb-deposit-status" : "eth-deposit-status",
                  deposit: deposit,
                  data: discordData
                }, null, 2));
              })

            Api.logToDiscord(JSON.stringify({
              traceId,
              type: isBnb ? "bnb-succeed" : "eth-succeed",
              txId: hash,
              data: discordData
            }, null, 2))
          })
          .on("error", err => {
            setSnackbar({message: "Oops.. Something happen, please contact our administrator (" + traceId + ")", type: 'danger'})
            Api.logToDiscord(JSON.stringify({
              traceId,
              type: isBnb ? "bnb-error" : "eth-error",
              err: err.message,
              data: discordData
            }, null, 2))
          })
      })
  }

  const submitDeposit = useCallback((token, address, amount) => {
    if (isTron) {
      handleTRC20Network(token, amount, address)
      return false;
    } else {
      handleEtherNetwork(token, amount, address)
      return false;
    }
  }, [web3, tokenContract, byxContract, coin, username]);

  const submitWithdrawal = useCallback((token, address, amount) => {
    setLoading(true);

    if (Number(amount) && Number(amount) >= 1 && username.length >= 4) {
        let networkType = ETH;
        if (isBnb) {
          networkType = BNB;
        } else if(isTron) {
          networkType = TRX
        }
        
        Api.sendWithdraw(address, amount, networkType, token)
          .then(resp => {
            setSnackbar({message: 'Your withdraw request has been sent.'})
            setAmount('')
          }).catch(e => {
            console.error(e);
            const errMessage = {
              token,
              amount,
              username: account,
              e: e.message,
            }
            if (e.response) {
              if (e.response.status === 441) {
                errMessage.detail = e.response.toString()
                setSnackbar({message: 'You have pending Withdrawal, try again later.', type:'danger'})
              } else {
                setSnackbar({message: 'Error: Withdrawal failed. \nKindly contact our helpdesk.', type:'danger'})
              }
            } else {
              setSnackbar({message: e.message, type:'danger'})
            }
          }).finally(e => {
            setLoading(false);
          })
    } else {
      // setSnackbar({message: "Minimum amount for withdraw is 50 CREDIT", type:'danger'})
      setLoading(false);
    }
  });

  const handleSubmit = useCallback(() => {
    if (isDeposit) {
      submitDeposit(auth.token, account, amount)
    } else {
      submitWithdrawal(auth.token, account, amount)
    }
  })

  useEffect(() => {
    if (!auth) return;
    getBalance()
    let mounted = true;
    if (mounted) setLoading(true);
    Api.getRate(auth.token)
      .then(res => mounted && setRate(res))
      .catch(console.error)
      .then(() => mounted && setLoading(false));

    return () => (mounted = false);
  }, [auth, tronOwnerAddress]);

  return <>
    <div className="container container--light container--bottom-nav">
      <BackNav title={`${(words ? words.filter(a => a.id === 'deposit').map(a => a.word).toString() : 'Deposit')} - ${(words ? words.filter(a => a.id === 'withdrawal').map(a => a.word).toString() : 'Withdrawal')}`} />
      <div className="content wallet">
        <div className="wallet__transfer-box">
          <span className="wallet__title">{transferTitle}</span>
          <div className="wallet__box">
            <div className="wallet__from-to">
              <span>{words ? words.filter(a => a.id === 'from').map(a => a.word).toString() : 'From'}</span>
              <span>{words ? words.filter(a => a.id === 'balance').map(a => a.word).toString() : 'Balance'}: {isDeposit ? balanceToken : balanceStream}</span>
            </div>
            <div className="wallet__nominal">
              <input className="wallet__input-amount" type="text" placeholder="0.0" readOnly={loading} onChange={e => debounceAmount(e.target.value)} value={amount} step=".0001" />
              <div className="wallet__max-amount" onClick={() => setAmount(isDeposit ? balanceToken : balanceStream)}>MAX</div>
              <div className="wallet__token">
                {
                  isBnb
                    ? (<span className="wallet__currency">{isDeposit ? 'BUSD' : creditTranslation}</span>)
                    : (<span className="wallet__currency">{isDeposit ? 'USDT' : creditTranslation}</span>)
                }
              </div>
            </div>
            {isDeposit && (<div className="wallet__to-token">{token}</div>)}
          </div>
          <div className="wallet__swap">
            <img src="img/icon/swap.png" onClick={() => { setIsDeposit(!isDeposit); setAmount((resultAmount && resultAmount > 0) ? resultAmount : ''); }} />
          </div>
          <div className="wallet__box">
            <div className="wallet__from-to">
              <span>{words ? words.filter(a => a.id === 'to').map(a => a.word).toString() : 'To'}</span>
              <span>{words ? words.filter(a => a.id === 'balance').map(a => a.word).toString() : 'Balance'}: {isDeposit ? balanceStream : balanceToken}</span>
            </div>
            <div className="wallet__nominal">
              <input className="wallet__input-amount" type="text" placeholder="0.0" readOnly value={resultAmount} step=".0001" />
              {
                isBnb
                  ? (<span className="wallet__currency">{isDeposit ? creditTranslation : 'BUSD'}</span>)
                  : (<span className="wallet__currency">{isDeposit ? creditTranslation : 'USDT'}</span>)
              }
            </div>
            {!isDeposit && (<div className="wallet__to-token">{token}</div>)}
          </div>
          <button className="btn btn--small btn--full " disabled={loading} onClick={() => handleSubmit()}>
            {loading ? (words ? words.filter(a => a.id === 'loading').map(a => a.word).toString() : 'Loading...') : (words ? words.filter(a => a.id === 'submit').map(a => a.word).toString() : 'Submit')}
          </button>
          <div className="wallet__rate">
            {isDeposit ? `1 ${isBnb ? 'BUSD' : 'USDT'} ≈ ${rateBuy} ${creditsTranslation}` : `1 ${creditsTranslation} ≈ ${(Math.floor((1 / rateSell) * 10000) / 10000)} ${isBnb ? 'BUSD' : 'USDT'}`}
          </div>
        </div>
      </div>
      <Footer />
      <BottomNav />
    </div>
  </>
}