/**
 * easy-peasy global state
 */
import { action, createStore, computed } from "easy-peasy";

const words = require("./dictionary.json");

export const AppStore = createStore({
  auth: null,
  setAuth: action((state, payload) => {
    const getDate = new Date();
    const parseDate = Date.parse(getDate);
    const expDate = window.localStorage.getItem("tokenExp");

    state.auth = payload;
    if (!payload) {
      window.localStorage.removeItem("token");
    }
    else {
      window.localStorage.setItem("token", JSON.stringify(payload));
      if (!expDate || parseDate > expDate) {
        window.localStorage.setItem("tokenExp", Date.now() + 864e5);
      }
    }
  }),

  notification: null,
  setNotification: action((state, payload) => {
    state.notification = payload;
  }),

  setUsernameCome: action((state, username) => {
    state.username = username;
  }),

  setUsername: action((state, username) => {
    window.localStorage.setItem("username", username);
  }),

  words: computed(state => {
    let arr = [];
    for (const a of words) {
      const word = a.pack[state.lang]
      const obj = {
        id: a.id,
        word: word
      }
      arr.push(obj)
    }
    return arr
  }),
  lang: (window.localStorage.getItem("language") || ((navigator.language || navigator.userLanguage).toLowerCase().includes('zh') ? "cn" : 'en')),
  setLang: action((state, lang) => {
    state.lang = lang;
    window.localStorage.setItem("language", lang);
  }),

  sidebarOpen: false,
  toggleSidebar: action((state, payload) => {
    state.sidebarOpen = !state.sidebarOpen;
  }),

  balance: 0,
  addBalance: action((state, payload) => {
    state.balance = payload;
  }),

  filter: '',
  setFilter: action((state, payload) => {
    state.filter = payload;
  }),

  currency: '',
  setCurrency: action((state, payload) => {
    state.currency = payload;
  }),

  coin: '',
  setCoin: action((state, payload) => {
    state.coin = payload;
  }),

  isWeb3: false,
  setIsWeb3: action((state, payload) => {
    state.isWeb3 = payload;
  }),

  isTron: false,
  setIsTron: action((state, payload) => {
    state.isTron = payload;
  }),

  isEth: false,
  setIsEth: action((state, payload) => {
    state.isEth = payload;
  }),

  isBnb: false,
  setIsBnb: action((state, payload) => {
    state.isBnb = payload;
  }),

  isBrowser: false,
  setIsBrowser: action((state, payload) => {
    state.isBrowser = payload;
  }),

  web3: null,
  setWeb3: action((state, payload) => {
    state.web3 = payload;
  }),

  provider: null,
  setProvider: action((state, payload) => {
    state.provider = payload;
  }),

  tronWeb: null,
  setTronWeb: action((state, payload) => {
    state.tronWeb = payload;
  }),

  account: '',
  setAccount: action((state, payload) => {
    state.account = payload;
  }),

  snackbar: {},
  setSnackbar: action((state, payload) => {
    const dt = { message: payload.message, type: (payload.type || '') }
    state.snackbar = dt
  }),
});