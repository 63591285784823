import { createContext } from "react";
import { v4 } from "uuid";

export class WS {

  constructor(uri, callbacks) {
    this.open = false;
    this.uri = uri;
    this._ws = null;
    this._listeners = new Map();
    this.callbacks = callbacks;
  }

  get listeners() {
    return this._listeners;
  }

  addListener(event, cb) {
    const listenId = v4();
    const listener = { event, cb };
    this._listeners.set(listenId, listener);
  }

  removeListener(listenId) {
    this._listeners.delete(listenId);
  }

  clearListener() {
    this._listeners.clear();
  }

  setOpen(val) {
    this.open = val;
    const openCB = this.callbacks.openChange;
    if (openCB) openCB(this.open);
  }

  connect() {
    if (this.open) {
      this._ws.close();
      this.setOpen(false);
    }

    this._ws = new WebSocket(this.uri);

    this._ws.onopen = e => {
      this.setOpen(true);
    };

    this._ws.onclose = e => {
      this.setOpen(false);
      this.connect();
    };

    this._ws.onerror = e => {
      console.error(e);
      this.setOpen(false);
      this.connect();
    };

    this._ws.onmessage = e => {
      try {
        const parsed = JSON.parse(e.data);
        console.log("EVENT", parsed);
        const entries = this._listeners.entries();
        for (const listener of entries) {
          if (listener[1].event === parsed.event) {
            listener[1].cb(parsed);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
  }

  send(event, data) {
    if (this.open) this._ws.send(JSON.stringify({ event, data }));
  }

  close() {
    if (this.open) return this._ws.close();
  }
}

export const WsContext = createContext(null);
export const BalanceContext = createContext(0);