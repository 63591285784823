import React from "react";
import { useStoreState } from "easy-peasy";
import { BackNav } from "../components/back-nav";

export function PromotionPage() {
  const words = useStoreState(state => state.words);

  return <>
    <div className="container container--light">
      <BackNav title={ words ? words.filter(a => a.id === 'promotion').map(a => a.word).toString() : 'Promotion' } />
      <div className="content">
        <center className="single-message"><b>Coming Soon</b></center>
      </div>
    </div>
  </>
}