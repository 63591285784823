import React from "react";
import { BackNav } from "../components/back-nav";
import { BottomNav } from "../components/bottom-nav";
import { useStoreState } from "easy-peasy";
import '../assets/scss/about.scss'; 
import {Footer} from "../components/footer";

export function AboutPage() {
  const words = useStoreState(state => state.words);

  return <>
    <div className="container container--light container--bottom-nav">
      <BackNav title={ words ? words.filter(a => a.id === 'about_us').map(a => a.word).toString() : 'About Us' } />
      <div className="content about">
        <div className="card card--action">
          <div className="card__content">
            <p className="about__item">{ words ? words.filter(a => a.id === 'about_aim').map(a => a.word).toString() : 'UBO aims to provide our customers the finest quality service, values, user friendly interface experience in a safe and secure gaming environment. Our Team strive to offer a highest quality across our wide selection of innovative products such as;' }</p>
            <div className="about__list">
                <div className="about__img-container">
                    <img src="img/about-us/casino.png"/>
                    <div>{ words ? words.filter(a => a.id === 'casino').map(a => a.word).toString() : 'Casino' }</div>
                </div>
                <div className="about__img-container">
                    <img src="img/about-us/slots.png"/>
                    <div>{ words ? words.filter(a => a.id === 'slot').map(a => a.word).toString() : 'Slot' }</div>
                </div>
                <div className="about__img-container">
                    <img src="img/about-us/niuniu.png"/>
                    <div>{ words ? words.filter(a => a.id === 'niuniu').map(a => a.word).toString() : 'Niuniu' }</div>
                </div>
                <div className="about__img-container">
                    <img src="img/about-us/fishing.png"/>
                    <div>{ words ? words.filter(a => a.id === 'fishing').map(a => a.word).toString() : 'Fishing' }</div>
                </div>
                <div className="about__img-container">
                    <img src="img/about-us/games.png"/>
                    <div>{ words ? words.filter(a => a.id === 'other_games').map(a => a.word).toString() : 'Other Games' }</div>
                </div>
            </div>
            
            <p className="about__item">{ words ? words.filter(a => a.id === 'about_excitement').map(a => a.word).toString() : '我们深知玩家对于能快速进行游戏的渴望，也感同身受您在等待交易完成时的迫切心情。因此我们向你保证所有存款和提款交易都将轻松，快捷和安全地进行。' }</p>
            <div className="about__list">
                <div className="about__img-container">
                    <img src="img/about-us/support.png"/>
                      <div>{ words ? words.filter(a => a.id === 'live_chat').map(a => a.word).toString() : 'Live Chat' }</div>
                </div>
                <div className="about__img-container">
                    <img src="img/about-us/email.png"/>
                      <div>{ words ? words.filter(a => a.id === 'email').map(a => a.word).toString() : 'Email' }</div>
                </div>
            </div>
            
            <p className="about__item">{ words ? words.filter(a => a.id === 'about_thankyou').map(a => a.word).toString() : 'Thank you for choosing UBO as your preferred choice of gaming.' }</p>
          </div>
        </div>
      </div>
      <Footer />
      <BottomNav />
    </div>
  </>
}