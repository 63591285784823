import React, { useState, useEffect } from "react";
import * as Api from "../api";
import { useStoreState } from "easy-peasy";
import { BackNav } from "../components/back-nav";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import '../assets/scss/faq.scss'; 
import { BottomNav } from "../components/bottom-nav";
import { Footer } from "../components/footer";

export function FaqPage() {
  const auth = useStoreState(s => s.auth);
  const [loading, setLoading] = useState(false);
  const words = useStoreState(state => state.words);
  const lang = useStoreState(l => l.lang);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    if (auth) {
      let mounted = true;
      setLoading(true);
      Api.getFaq(auth.token, lang)
        .then(res => {
          if (mounted) {        
            setFaqs(res.data.map(r => ({
              q: r.title,
              a: r.content,
              o: false,
            })));
          }
        })
        .catch(console.error)
        .then(() => setLoading(false));
      
      return () => {
        mounted = false;
      }
    }
  }, [auth]);

  return <>
    <div className="container container--light container--bottom-nav">
      <BackNav title={ words ? words.filter(a => a.id === 'faq').map(a => a.word).toString() : 'FAQ' } />
      <div className="content faq">
        <div className="card card--full">
          <div className="card__content">
            <Accordion className="faq__accordion" allowZeroExpanded>
            {
              loading
                ? <center className="single-message"><b>{ ( words ? words.filter(a => a.id === 'loading').map(a => a.word).toString() : 'Loading...' ) }</b></center>
                : faqs.map((faq, i) => (
                  <AccordionItem className="faq__accordion-item" key={ 'faq-' + i }>
                    <AccordionItemHeading>
                      <AccordionItemButton className="faq__accordion-button">
                        { faq.q }
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="faq__accordion-panel">
                      <p>
                        { faq.a }
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))
            }
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
      <BottomNav />
    </div>
  </>
}