import { useStoreState, useStoreActions } from "easy-peasy";
import React, { useState, useCallback, useEffect, useMemo, useContext } from "react";
import { useBalance, useBalanceStream, useFormattedUsername, useNavigate, copyText } from "../hooks";

import MarqueeText from 'react-marquee-text-component';
import { HomeNav } from "../components/home-nav";
import { BottomNav } from "../components/bottom-nav";
import { Footer } from "../components/footer";

// import { Web3Context } from "../web3-context";
import * as Api from "../api";
import classNames from 'classnames'
import { useLocation } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';

import _, { upperCase } from "lodash";
import '../assets/scss/home.scss'; 
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const filterMenus = [
  {
    title: "Casino",
    type: "casino",
  },
  {
    title: "Slot",
    type: "slot",
  },
  {
    title: "Fishing",
    type: "fishing",
  },
  {
    title: "RNG",
    type: "rng",
  },
  {
    title: "Live",
    type: "live_casino",
  }
]

export function HomePage() {
  // const web3 = useContext(Web3Context);
  const [loading, setLoading] = useState(false);
  const [filter, switchFilter] = useState('casino');
  const [page] = useState(1);
  const auth = useStoreState(s => s.auth);
  const localuser = window.localStorage.getItem("username");
  const lang = useStoreState(l => l.lang);
  const [balance] = useBalance(setLoading);
  const balanceStream = useBalanceStream();
  const [games, setGames] = useState([]);
  const [slides, setSlides] = useState([]);
  const [viewGameCount, setViewGameCount] = useState(1);
  const viewGameMultiplier = 12;
  const gameShown = viewGameCount * viewGameMultiplier;
  const setSnackbar = useStoreActions(s => s.setSnackbar);
  const [slideIndex, setSlideIndex] = useState(0);

  const words = useStoreState(state => state.words);
  const navigate = useNavigate();
  const typeLiveCasino = 'live_casino'

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex(s => s + 1);
    }, 3000);
    
    return () => {
      clearInterval(interval);
    };
  }, []);

  const location = useLocation();

  const filteredGame = games.filter(game => game.type.toLowerCase() === filter);

  const type = useMemo(() => {
  const sp = new URLSearchParams(location.search);

    return sp.get("type");
  }, [location.search]);

  useEffect(() => {
    let mounted = true;
    if (mounted) setLoading(true);

    if (auth) {
      Api.disconnectFromBaison(localuser, auth.token)
        .then(res => {
          console.log(res)
        })
        .catch(console.error)
    }

    Api.getGames(page, type)
      .then(games => {
        if (mounted) {
          setGames(games.games)
        }
      })
      .catch(console.error)
      .then(() =>{ 
        if (mounted) {
          setLoading(false)
        }
      });

    return () => {
      mounted = false;
    }
  }, [page, type, localuser, auth]);

  useEffect(() => {
    let mounted = true;

    Api.getSliders(lang)
      .then(resp => {
        if (mounted) {
          setSlides(resp)
        }
      })
      .catch(console.error);

    return () => {
      mounted = false;
    }
  }, [auth, lang]);

  const handleLaunch = useCallback((id) => {
    if(!auth) navigate("/wallet");
    setLoading(true);

    if (!balanceStream) {
      setSnackbar({message:'Insufficient balance.', type: 'danger'})
      navigate("/wallet");
      setLoading(false);
    } else {
      Api.launchGame(id, auth.token, lang)
        .then(resp => {
          if (resp.error && resp.type === "ticket-lock") {
            setSnackbar({message: 'Previous game is still running (" + resp.game.name + "). Please try again later or reconnect to previous game.', type: 'danger'})
          } else {
            window.location = resp.url
          }
        })
        .catch(e => {
          setSnackbar({message: 'Cannot play game, Please contact our administrator.', type: 'danger'})
        })
        .then(() => setLoading(false))
    }

  }, [auth, balanceStream, lang]);

  const username = useFormattedUsername();

  return (
    <>
      <div className="container container--home-nav container--bottom-nav">
        <HomeNav />
        <div className="content">
          <div className="slider">
            { slides.length > 0 && 
              (
                <>
                  <div className="gold-border gold-border--slider"/> 
                    <Carousel
                      showArrows={true}
                      showThumbs={false}
                      stopOnHover={false}
                      infiniteLoop={true}
                      autoPlay={true}
                      interval={2500}
                      onClickItem={e => window.open(slides[e].value)}
                    >
                      {
                        slides.map((s, i) => <img key={i} src={Api.API_URL + "/public/" + s.url} className="lobby-slider__img" />)
                      }
                    </Carousel>
                  <div className="gold-border gold-border--bottom"/>
                </>
              )
            }
          </div>
          <div className="marquee">
            <div className="marquee__icon">
              <img src="img/icon/speaker.png" className="marquee__icon" alt="speaker" />
            </div>
            <div className="marquee__message">
              <MarqueeText repeat={3} duration={10} text={ words ? words.filter(a => a.id === 'marquee_text').map(a => a.word).toString() : 'To all new players, deposit and be the first to receive UBO limited time bonus! Start winning now!' } />
            </div>
          </div>
          <div className="content-wrapper">
            <div className="deposit" onClick={() => { navigate('/wallet') }}>
              <div className="deposit__wrapper">
                <div className="deposit__amount-wrapper">
                  <div className="deposit__label">{ words ? words.filter(a => a.id === 'enter_deposit_amount').map(a => a.word).toString() : 'Enter deposit amount' }</div>
                  <div className="deposit__amount">$100.00</div> 
                </div>
                
                <div className="deposit__action-wrapper">
                  <div className="deposit__currency">USDT</div>
                  <button className="btn btn--small">{ words ? words.filter(a => a.id === 'deposit').map(a => a.word).toString() : 'deposit'}</button>
                </div>
              </div>
            </div>

            <div className="game-wrapper">
              <ul className="filter-list">
                {filterMenus.map((dt, i) => {
                  let filterClass = classNames({
                    'filter-list__item': true,
                    'filter-list__item--active': (dt.type === filter),
                    'filter-list__item--cn': (lang === 'cn'),
                  })

                  let filterIconClass = [
                    'filter-list__icon',
                    `filter-list__icon--${dt.type}`
                  ]
                  filterIconClass = filterIconClass.join(' ')

                  return (<li className={ filterClass } onClick={() => {switchFilter(dt.type); setViewGameCount(1)}} key={`filter-${i}`}>
                    <i className={filterIconClass}></i>
                    { words ? words.filter(a => a.id === dt.title.toLowerCase()).map(a => a.word).toString() : dt.title }
                  </li>)
                })}    
              </ul>
              <ul className="game-list">
                {loading ? <li className="game-list__loading">{ ( words ? words.filter(a => a.id === 'loading').map(a => a.word).toString() : 'Loading...' ) }</li> : 
                  filteredGame.slice(0,gameShown).map((game, i) => (
                    <li className={`game-list__item ${game.type==typeLiveCasino ? "game-list__item--full" : ""}`} onClick={ () => handleLaunch(game.id, balance) } key={`game-${i}`}>
                      <img className="game-list__preview" src={game.type==typeLiveCasino ? '/img/game-icon/dg.png' : game.img} alt={game.name} />
                    </li> 
                  ))
                }
                {
                  (gameShown < filteredGame.length && !loading ) && 
                  <li className="game-list__show-more" onClick={() => setViewGameCount(viewGameCount + 1)}>
                    <button className="btn btn--small">{ words ? words.filter(a => a.id === 'view_more').map(a => a.word).toString() : 'View More' }</button>
                  </li> 
                }
                {
                  (!loading && filteredGame.length == 0) && <li className="game-list__loading">{ words ? words.filter(a => a.id === 'no_data').map(a => a.word).toString() : 'No Available Data' }</li>
                }
              </ul>
            </div>
          </div>
        </div>
        <Footer />
        <BottomNav />
      </div>
    </>
  )
}