import React, { useCallback, useState } from "react";
import * as Api from "../api";
import { MenuActions } from "../components/menu-actions";
import { useMenuAction, useNavigate, useSiteContainerClass } from "../hooks";


export function PromotionDetailPage() {

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    id: 1,
    title: "Get Daily Bonus UP to 100%",
    info: [
      "Redeem extra credit every day and get extra with 0 turnover. GRAB it now!",
      "Deposit 1000CNY and get extra 30CNY credit daily for 30 days (900CNY extra). Credit can directly used to play with 0 turnover. Extra credit cannot be withdrawn but can use to play to earn MORE!"
    ],
  });

  const buyAction = useCallback((id) => {
    console.log(id);
  }, []);

  const navigate = useNavigate();

  const [open, toggleMenu] = useMenuAction();
  const classes = useSiteContainerClass(open);

  if (loading) {
    return <div className="site-container">
    </div>
  }

  return <>
    <div className={classes}>
      <div className={"promotion-header shifting-header " + (open ? "shifting-header_menu" : "")}>
        <div className="promotion-header__back" onClick={e => navigate("/promotion")}>
          <img src="/img/icon/back.png" alt="Back" className="promotion-header__back-icon" />
        </div>
        <h2 className="promotion-header__title">Promotion</h2>
        <div className="promotion-header__menu toggle-menu" onClick={toggleMenu}>
          <img src="/img/icon/burger.png" alt="Back" className="promotion-header__menu-icon" />
        </div>
      </div>

      <div className="promotion-body">
        <div className="promotion-body__item">
          <img src="/img/slider/slide-1.jpg" alt="Promotion" className="promotion-body__item-img" />
          <div className="promotion-body__item-detail">
            <h2 className="promotion-body__item-title">{data.title}</h2>
            {
              data.info.map(info => (
                <p className="promotion-body__item-info">{info}</p>
              ))
            }
            <div className="promotion-body__item-action">
              <button className="promotion-body__item-buy"
                onClick={() => buyAction(data.id)}
              >
                Buy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MenuActions open={open} />
  </>
}